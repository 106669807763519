<template>
  <div>
    <c-card :title="`조직도`" class="cardClassDetailForm firefighting-org-card">
      <!-- <template slot="card-button">
        <q-btn-group outline >
          <c-btn
            v-show="editable" 
            label="삭제"
            icon="delete_forever"
            @beforeAction="deleteInfo"
            @btnCallback="deleteInfoCallback" />
          <c-btn
            v-show="editable" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="document"
            :mappingType="mappingType"
            label="저장"
            icon="save"
            @beforeAction="saveInfo"
            @btnCallback="saveInfoCallback" />
        </q-btn-group>
      </template> -->
      <template slot="card-detail">
        <div ref="org" class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">

        </div>
      </template>
    </c-card>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
/* eslint-disable no-unused-vars */
import { uid } from 'quasar';
import * as d3 from 'd3';
import { OrgChart } from 'd3-org-chart';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'selfDefenseFireBrigade',
  props: {
    sopFireFightingDocumentClassCd: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      searchParam: {
        plantCd: null,
        sopFireFightingDocumentClassCd: '',
        sopFireFightingOrgFrameCd: '',
      },
      chartReference: null,
      orgs: [],
      currentNode: {},
      editable: true,
      isSave: false,
      listUrl: '',
      saveUrl: '',
      insertDeleteUrl: '',
      deleteUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    window.addEventListener('resize', this.setSize);
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setSize);
  },
  computed: {
    isOld() {
      return Boolean(this.document.sopFireFightingDocumentId)
    },
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.fft.oraganizaionUserDept.list.url
      this.saveUrl = transactionConfig.fft.oraganizaionUserDept.insert.url
      this.insertDeleteUrl = transactionConfig.fft.oraganizaionUserDept.insertDelete.url
      this.deleteUrl = transactionConfig.fft.oraganizaionUserDept.delete.url
      // code setting
      this.$set(this.searchParam, 'plantCd', this.$store.getters.user.plantCd)
      this.$set(this.searchParam, 'sopFireFightingDocumentClassCd', this.sopFireFightingDocumentClassCd)
      if (this.sopFireFightingDocumentClassCd !== 'FFDC000013') {
        this.$set(this.searchParam, 'sopFireFightingOrgFrameCd', 'FFOFC00001')
      } else {
        this.$set(this.searchParam, 'sopFireFightingOrgFrameCd', 'FFOFC00002')
      }
      // list setting
      this.getDetail();
    },
    getDetail() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.orgs = _result.data;
        this.setChart();
      },);
    },
    setChart() {
      if (!this.chartReference) {
        this.chartReference = new OrgChart();
      }
      let thisVue = this;
      this.chartReference
        .container(this.$refs.org) // node or css selector
        .data(this.orgs)
        .initialExpandLevel(4)
        .svgHeight(500)
        .nodeHeight((d) => 120)
        .nodeWidth((d) => {
          return 220;
        })
        .childrenMargin((d) => 70)
        // .compactMarginBetween((d) => 0)
        // .compactMarginPair((d) => 1000)
        // .neightbourMargin((a, b) => 25)
        .siblingsMargin((d) => 100)
        .compact(false)
        .buttonContent(({ node, state }) => {
          return `<div style="px;color:#716E7B;border-radius:5px;padding:4px;font-size:10px;margin:auto auto;background-color:white;border: 1px solid #E4E2E9"> <span style="font-size:9px">${
            node.children
              ? `<i class="fas fa-angle-up"></i>`
              : `<i class="fas fa-angle-down"></i>`
          }</span> ${node.data._directSubordinates}  </div>`;
        })
        .nodeContent(function (d, i, arr, state) {
          let nodeContent = '';
          if (d.data.connectionFlag === 'Y') {
            nodeContent = `
            <div class="org-node node-connection">
            </div>`
          } else if (d.data.level === 1) {
            // 최상위
            nodeContent = `
            <div class="row org-node node-1">
              <div class="nodeName"> ${d.data.name} </div>
              <div class="subName"> ${d.data.subName} </div>`
            if (d.data.userDepts && d.data.userDepts.length > 0) {
              d.data.userDepts.forEach(userDept => {
                nodeContent += `<div class="userDeptName">
                  ${userDept.userDeptName}
                </div>`
              })
            }
            nodeContent += `</div>`
          } else if (d.data.level === 2 || d.data.level === 3) {
            nodeContent = `
            <div class="row org-node node-2">
              <div class="col">
                <div class="nodeName"> ${d.data.name} </div>
                <div class="subName"> ${d.data.subName} </div>
              </div>`
            if (d.data.userDepts && d.data.userDepts.length > 0) {
              d.data.userDepts.forEach(userDept => {
                nodeContent += `<div class="col-aotu userDeptName">
                  <div class="userName"> ${userDept.userDeptName} </div>
                  <div class="phone"> ( ${userDept.mobileNo} ) </div>
                </div>`
              })
            }
            nodeContent += `</div>`
          } else {
            nodeContent = `
            <div class="org-node node-3">
              <div class="nodeName"> ${d.data.name} </div>
              <div class="subName"> ${d.data.subName} </div>
              <div class="q-list q-list--separator">`

            if (d.data.userDepts && d.data.userDepts.length > 0) {
              d.data.userDepts.forEach(userDept => {
                nodeContent += `<div role="listitem" class="q-item q-item-type row no-wrap">
                  <div class="q-item__section column q-item__section--avatar q-item__section--side justify-center">
                    <i aria-hidden="true" role="presentation" class="q-icon notranslate material-icons">info_outline</i>
                  </div>
                  <div class="q-item__section column q-item__section--main justify-center">
                    <div class="q-item__label">${userDept.userDeptName}</div>
                    <div class="q-item__label q-item__label--caption text-caption">${userDept.mobileNo}</div>
                  </div>
                  <div class="q-item__section column q-item__section--top q-item__section--side justify-start" >
                    <button tabindex="0" type="button" class="q-btn q-btn-item non-selectable no-outline q-btn--flat q-btn--round text-red q-btn--actionable q-focusable q-hoverable q-btn--wrap fire-node-close" sopFireFightingOrganizaionUserDeptId="${userDept.sopFireFightingOrganizaionUserDeptId}">
                      <span class="q-focus-helper"></span>
                      <span class="q-btn__wrapper col row q-anchor--skip">
                        <span class="q-btn__content text-center col items-center q-anchor--skip justify-center row">
                          <i aria-hidden="true" role="img" class="q-icon notranslate material-icons">close</i>
                        </span>
                      </span>
                    </button>
                  </div>
                </div>`
              })
            }
                    // <i aria-hidden="true" role="presentation" class="q-icon text-red notranslate material-icons">close</i>
            nodeContent += `</div>
            </div>`
          }
          
          return nodeContent;
        })
        .onNodeClick((d) => this.openUserPop(d.data))
        .render()
        .fit();

      setTimeout(() => {
        let closeButton = document.getElementsByClassName("fire-node-close");
        if (closeButton) {
          this.$_.forEach(closeButton, htmlNode => {
            htmlNode.addEventListener("click", (e) => {
              e.preventDefault();
              e.stopPropagation();
              this.$http.url = this.$format(this.deleteUrl, htmlNode.getAttribute('sopFireFightingOrganizaionUserDeptId'));
              this.$http.type = 'DELETE';
              this.$http.request(() => {
                window.getApp.$emit('APP_REQUEST_SUCCESS');
                this.getDetail();
              },);
            });
          })
        }
      }, 200);
      this.setSize();
    },
    setSize() {
      this.chartReference.svgHeight(window.innerHeight - 150).render().fit()
    },
    openUserPop(data) {
      if (data.connectionFlag === 'Y') return;
      this.currentNode = data;
      this.popupOptions.title = '사용자 검색'; // 사용자 검색
      this.popupOptions.param = {
        type: data.level !== 5 ? 'single' : 'multiple',
        plantCd: this.$store.getters.user.plantCd
      };
      this.popupOptions.target = () => import(`${'@/pages/common/user/userPop.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        // 사용자 또는 부서
        let insertData = [];
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.currentNode, node => {
            return node.deptCd === item.deptCd || node.userId === item.userId
          }) === -1) {
            insertData.push({
              sopFireFightingOrganizaionUserDeptId: uid(),  // 소방 조직도 부서/사용자 일련번호
              sopFireFightingOrganizaionFrameId: this.currentNode.sopFireFightingOrganizaionFrameId,  // 소방 조직도 틀 일련번호
              sopFireFightingDocumentClassCd: this.sopFireFightingDocumentClassCd,  // 문서 구분 코드
              userId: item.userId,  // 사용자 id
              userName: item.userName,  // 사용자명
              mobileNo: item.mobileNo,  // 사용자명
              deptCd: item.deptCd,  // 부서 코드
              deptName: item.deptName,  // 부서명
              userDeptName: item.userName ? item.userName : item.deptName,
              regUserId: this.$store.getters.user.userId,  // 등록자
              editFlag: 'C'
            })
          }
        })
        this.$http.url = this.currentNode.level !== 5 ? this.insertDeleteUrl : this.saveUrl;
        this.$http.type = 'POST';
        this.$http.param = insertData;
        this.$http.request(() => {
          window.getApp.$emit('APP_REQUEST_SUCCESS');
          this.getDetail();
        },);
      }
    },
  }
};
</script>
<style lang="sass">
.firefighting-org-card
  .customCardbody
    margin: 0 !important
    padding: 0 !important
  path
    stroke-width: 2 !important
    stroke: #B4B4B8 !important

.org-node
  margin-top:-1px
  margin-left:-1px
  position: absolute !important

.node-1
  border-radius:50%
  border: 16px solid rgb(163, 151, 198)
  width:150px
  height:150px
  left: 30px
  top: -20px
  .nodeName
    font-size: 25px
    font-weight: 800
    color:#08011E
    margin-right: 20px
    padding-left: 15px
    margin-top: 22px
    border-bottom: 4px solid rgb(163, 151, 198)
  .subName
    color:#08011E
    margin-left:70px
    margin-top:-10px
    font-size:15px
    font-weight: 500
  .userDeptName
    text-align: center
    width: 100%
    font-size: 22px
    font-weight: 800
    padding-bottom: 20px

.node-2
  border-radius:10px
  // border: 1px solid #E4E2E9
  background-color:#0C85CA
  width:350px
  height:100px
  right: -70px
  padding: 5px
  .nodeName
    font-weight: 800
    font-size:25px
    color:#ffffff
    margin-left:20px
    margin-top:15px
  .subName
    color:#ffffff
    margin-left:30px
    margin-top:3px
    font-size:15px
    font-weight: 500
  .userDeptName
    text-align: center
    padding-right: 30px
    padding-left: 30px
    border-radius: 15px
    border: 4px solid #fff
    .userName
      font-weight: 800
      font-size:25px
      color:#ffffff
      margin-left:0px
      margin-top:15px
    .phone
      color:#ffffff
      margin-left:0px
      margin-top:3px
      font-size:15px
      font-weight: 500

.node-3
  border-radius:10px
  border: 1px solid #B4B4B8
  // background-color:#F2EFE5
  width:250px
  min-height:100px
  top: 20px
  right: -10px
  .nodeName
    font-weight: 800
    font-size: 16px
    color: #fff
    padding-bottom: 10px
    padding-top: 5px
    text-align: center
    background: #0D9276
    border-radius: 5px
  .subName
    color:#08011E
    margin-left:60px
    margin-top:3px
    font-size:15px
    font-weight: 500
  .q-item
    font-size: 17px
    font-weight: 600
    .q-icon
      font-size: 33px


.node-connection
  border: 1px solid #B4B4B8
  left:110px
  top:-20px
  width:0px
  height:130px
</style>